import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FlightSegmentNode } from '@generated/graphql';

@Component({
  selector: 'itinerary-segment-short',
  templateUrl: './itinerary-segment-short.component.html',
  styleUrls: ['./itinerary-segment-short.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItinerarySegmentShortComponent {
  @Input() segment: FlightSegmentNode;
}
